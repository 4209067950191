import React from "react";

import "./main-footer.scss";

const MainFooter = () => {
  return (
    <footer className="main-footer-block">
      <div className="main-footer-wrapper">
        <div className="main-footer-contacts">
          <a className="main-footer-contact-item" href="mailto:join@zimtra.ky">
            <img
              src={`${process.env.PUBLIC_URL}/mail.svg`}
              alt="mail"
              width="20"
              height="20"
            />
            <span>join@zimtra.ky</span>
          </a>
          <a className="main-footer-contact-item" href="tel:+18663818846">
            <img
              src={`${process.env.PUBLIC_URL}/phone.svg`}
              alt="phone"
              width="20"
              height="20"
            />
            <span>+1-866-381-8846</span>
          </a>
          <a
            href="https://www.google.com/maps?q=90+N+Church+St.+George+Town+Cayman+Islands"
            className="main-footer-contact-item"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/pin.svg`}
              alt="address"
              width="20"
              height="20"
            />
            <span>90 N Church St. George Town Cayman Islands</span>
          </a>
        </div>
        <p>© 2023 ZIMTRA, All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default MainFooter;
