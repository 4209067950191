import axios from "axios";
import { LOG_IN_TOKEN } from "../reducers/types";
import Cookies from "js-cookie";

export const AuthAPI = {
  signup: async (userData) => {
    const response = await instance.post("/registration/signup", userData);
    return response;
  },
  signin: async (userData) => {
    const response = await instance.post("/registration/signin", userData);
    return response;
  },
  logout: async () => {
    const response = await instance.get("/logout", {
      headers: setHeader(LOG_IN_TOKEN),
    });
    return response;
  },
  recoverPassword: async (userData) => {
    const response = await instance.post("/registration/forgot-password", userData);
    return response;
  },
  changePassword: async (userData, token) => {
    const response = await instance.post("/registration/change-password", userData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
  checkTokenValidity: async (token) => {
    const response = await instance.get("/registration/test-token", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
  activate: async (token, userData) => {
    const response = await instance.put(`/registration/activate`, userData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
  getToken: () => {
    if (Cookies.get(LOG_IN_TOKEN)) {
      return Cookies.get(LOG_IN_TOKEN)
    }
    if (window.location.hostname === "localhost") {
      return localStorage.getItem(LOG_IN_TOKEN)
    }
    return null
  },
  getSessionToken: () => sessionStorage.getItem(LOG_IN_TOKEN)
};

export const setHeader = (type) => {
  switch (type) {
    case LOG_IN_TOKEN:
      return { Authorization: `Bearer ${AuthAPI.getToken()}` };
    default:
      break;
  }
};

const baseURL = process.env.REACT_APP_API_URL || "http://accountio.dev.ath/onboarding2/api";

export const instance = axios.create({
  baseURL,
});
