import React from "react";
import { useDispatch } from "react-redux";
import "./demo-page.scss";
import { profileThunks } from "../../actions-thunks/profile-thunks";

const DemoPage = () => {
  const dispatch = useDispatch();

  const onUpgradeClick = () => {
    dispatch(profileThunks.demoProfileStop());
  };

  return (
    <div className="demo-page-section">
      <div className="demo-page-wrapper">
        <h4>FREE DEMO</h4>
        <div className="demo-message">
          <p>Dear Trader,</p>
          <br />
          <p>
            Your 14-day free demo trading period starts with an email containing
            comprehensive instructions on how to download Sterling Trader® Pro
            trading platform, and which credentials to use to log in.
          </p>
          <br />
          <p>
            We at Zimtra hope you're finding your experience with our free demo
            trading account enlightening.
          </p>
          <p>
            A demo account is a stage of discovery and learning in the dynamic
            world of trading where every decision contributes to your growth as
            a trader. These learnings are the foundation for successful
            real-world trading.
          </p>
          <p>
            Remember, every successful trader began their journey with learning.
          </p>
          <br />
          <p>
            When you are ready to turn your "practice" profits to real ones,
            upgrade to live trading account. Join Zimtra team of traders.
          </p>
          <br />
          <p>
            NOTE: Once you upgrade to live, your demo will no longer be
            available.
          </p>
        </div>
        <hr />
        <button class="main-button" onClick={onUpgradeClick}>
          upgrade to live
        </button>
      </div>
    </div>
  );
};

export default DemoPage;
