import { AuthAPI } from "../api/auth-api";
import { profileAPI } from "../api/profile-api";
import {
  ACTIVATE_ERROR,
  SIGN_UP_ERROR,
  SIGN_IN_ERROR,
  SUCCESS,
  CHECK_TOKEN_ERROR,
  ERROR,
  PASSWORD_RECOVERY_ERROR,
  TO_SIGN_UP_CONFIRMATION,
  NEW_PASSWORD__ERROR,
  EMAIL_TOKEN,
  TO_SIGN_IN,
  TO_HOME_PAGE,
  LOG_IN_TOKEN,
} from "../reducers/types";
import { utilityFns } from "../ui/utilities/utility-functions";
import {
  setErrorAC,
  activationRequestStatusAC,
  setLoadingAC,
  setAuthenticationAC,
  changePasswordTokenStatusAC,
  triggerRedirectAC,
  emailSendStatusAC,
  setShowSpinnerAC,
} from "./actions";

const reloadPage = () => {
  if (typeof window !== 'undefined') {
    window.location.reload();
  };
};


//Auth thunks
export const authThunks = {
  signupTC: (userData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await AuthAPI.signup(userData);
      if (response.status === 200) {
        dispatch(
          triggerRedirectAC({
            direction: TO_SIGN_UP_CONFIRMATION,
            status: true,
          })
        );
      }
      dispatch(setErrorAC({ type: SIGN_UP_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        activationRequestStatusAC({
          type: ERROR,
          message: `failed to send to ${userData.email}`,
        })
      );
      dispatch(
        setErrorAC({
          type: SIGN_UP_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  signinTC: (userData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await AuthAPI.signin(userData);
      if (response.status === 200 && !!response.data?.redirectUrl) {
        window.parent.location.replace(response.data?.redirectUrl)
        return
      }
      if (response.status === 200 && response.data?.success) {
        if (window.location.hostname === "localhost") {
          localStorage.setItem(LOG_IN_TOKEN, response.data.token)
        }
        localStorage.removeItem(EMAIL_TOKEN);
        dispatch(setAuthenticationAC(true));
      }
      dispatch(setErrorAC({ type: SIGN_IN_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SIGN_IN_ERROR,
          message: error.response?.data?.message,
        })
      );
      dispatch(setLoadingAC(false));
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  signoutTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      utilityFns.eraseTokens();
      utilityFns.redirectToLanding("logout")
    } catch (error) {
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  activateTC: (token, bodyPayload) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await AuthAPI.activate(token, bodyPayload);
      if (response.status === 200) {
        if(window.location.hostname.split(".")[0] === "zimtra"){
          window.fbq('track', 'Lead');
        } 
        dispatch(setAuthenticationAC(true))
        dispatch(
          triggerRedirectAC({
            direction: TO_HOME_PAGE,
            status: true,
          })
        );
      }
      dispatch(setErrorAC({ type: ACTIVATE_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        changePasswordTokenStatusAC({status: false, minutesToExpiration: null})
      );
      dispatch(
        setErrorAC({
          type: ACTIVATE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  recoverPasswordTC: (userData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await AuthAPI.recoverPassword(userData);
      if (response.status === 200) {
        localStorage.setItem(EMAIL_TOKEN, userData.email);
        dispatch(
          emailSendStatusAC({
            type: SUCCESS,
            message: `${userData.email}`,
          })
        );
      }
      dispatch(setErrorAC({ type: PASSWORD_RECOVERY_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: PASSWORD_RECOVERY_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  changePasswordTC: (password, token) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const rememberme =
        localStorage.getItem("onboarding2-rememberme") === "yes" ? true : false;
      const response = await AuthAPI.changePassword(password, token);
      if (response.status === 200) {
        dispatch(authThunks.signoutTC());
        if (rememberme) {
          localStorage.setItem("onboarding2-rememberme", "yes");
        } else {
          localStorage.setItem("onboarding2-rememberme", "no");
        }
        localStorage.removeItem(EMAIL_TOKEN);
        dispatch(setAuthenticationAC(true));
        reloadPage();
      }
      dispatch(setErrorAC({ type: NEW_PASSWORD__ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: CHECK_TOKEN_ERROR,
          message: 'The token has expired.',
        })
      );
      dispatch(
        setErrorAC({
          type: NEW_PASSWORD__ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  checkActivateTokenValidityTC: (token) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      dispatch(setShowSpinnerAC(true));
      const response = await AuthAPI.checkTokenValidity(token);
      if (response.status === 200 && response.data.success) {
        const minutesToExpiration = Math.round((response.data.expires - (Date.parse(new Date()) / 1000)) / 60)
        dispatch(
          changePasswordTokenStatusAC({status: true, minutesToExpiration})
        );
      }
      dispatch(setErrorAC({ type: CHECK_TOKEN_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        changePasswordTokenStatusAC({status: false, minutesToExpiration: null})
      );
      dispatch(
        setErrorAC({
          type: CHECK_TOKEN_ERROR,
          message: 'The token has expired.',
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
      dispatch(setShowSpinnerAC(false));
    }
  },
  checkRedirectTokenValidityTC: (token) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      dispatch(setShowSpinnerAC(true));
      const response = await AuthAPI.checkTokenValidity(token);
      if (response.status === 200 && response.data.success) {
        dispatch(setAuthenticationAC(true));
      }
      dispatch(setErrorAC({ type: CHECK_TOKEN_ERROR, message: "" }));
    } catch (error) {
          console.log('Invalid token')
    } finally {
      dispatch(setLoadingAC(false));
      dispatch(setShowSpinnerAC(false));
    }
  },
  checkTokenTC: () => async (dispatch) => {
    try {
      dispatch(setShowSpinnerAC(true));
      dispatch(setLoadingAC(true));
      const response = await profileAPI.getProfile(true);
      if (response.status === 200) {
        dispatch(setAuthenticationAC(true));
      }
      if (response === "null") {
        dispatch(triggerRedirectAC({ direction: TO_SIGN_IN, status: true }));
      }
      dispatch(setErrorAC({ type: CHECK_TOKEN_ERROR, message: "" }));
    } catch (error) {
      if (window.location.hostname === "localhost"){
      } else {
        dispatch(authThunks.signoutTC());
        dispatch(
          setErrorAC({
            type: CHECK_TOKEN_ERROR,
            message: 'The token has expired.',
          })
        );
        dispatch(triggerRedirectAC({ direction: TO_SIGN_IN, status: true }));
      }
    } finally {
      dispatch(setShowSpinnerAC(false));
      dispatch(setLoadingAC(false));
    }
  },
};
