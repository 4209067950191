import { instance, setHeader } from "./auth-api";
import { LOG_IN_TOKEN } from "../reducers/types";

const ACCOUNTIOAPI = `static`;

// const baseURL = process.env.REACT_APP_API_URL;
//"https://accountio.dev.ath";
// const instance = axios.create({
//   baseURL,
// });
const getAccountioSimple = async (ep) => {
  const result = await instance.get(`${ACCOUNTIOAPI}/${ep}`, {
    headers: setHeader(LOG_IN_TOKEN),
  });
  return [ep, result];
};

const getCalculationRates = () => {
  return getAccountioSimple("calculationrates");
};

const getCountryRates = () => {
  return getAccountioSimple("countryrates");
};

const getCountries = () => {
  return getAccountioSimple("country");
};

const getMarketdataProviders = () => {
  return getAccountioSimple("marketdataproviders");
};

const getTradingPlatforms = () => {
  return getAccountioSimple("tradingplatforms");
};

const getAgreementTemplate = () => {
  return getAccountioSimple("documenttemplate");
};

const getAccountType = () => {
  return getAccountioSimple("accounttypes");
};

export const accountio = {
  getCalculationRates,
  getCountryRates,
  getMarketdataProviders,
  getTradingPlatforms,
  getAgreementTemplate,
  getCountries,
  getAccountType,
};
