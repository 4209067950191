import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Input from "../../utilities/element/Input";
import DateInput from "../../utilities/element/DateInput";
import Checkbox from "../../utilities/element/Checkbox";
import Dropdown from "../../utilities/element/Dropdown";
import UploadFile from "../../utilities/element/UploadFile";
import { utilityFns } from "../../utilities/utility-functions";
import { profileAPI } from "../../../api/profile-api";
import { profileThunks } from "../../../actions-thunks/profile-thunks.js";

import "./IDVerification.scss";
import { updateFilesAC } from "../../../actions-thunks/actions";

const IDtypes = [
  { value: "passport", text: "Passport" },
  { value: "idcard", text: "ID card" },
  { value: "driverlicense", text: "Driver license" },
];
const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
const ALLOWED_IMAGE_EXT = ["jpg", "jpeg", "png", "pdf"];


const IDVerificationStep = (props) => {
  const { localState, setLocalState, setToastInfo, setToastInfoData } = props;
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState({});
  const [deleteImage, setDeleteImage] = useState(null);

  const { files, profile } = localState;
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "isexpiry") {
      if (checked) {
        setLocalState({ ...localState, profile: { ...localState.profile, [name]: checked } });
      } else {
        setLocalState({ ...localState, profile: { ...localState.profile, [name]: checked, "idexpirydate": null } });
      }
      return
    }
    setLocalState({ ...localState, profile: { ...localState.profile, [name]: value } });
  };

  //detect image to delete from loading state after uploading
  useEffect(() => {
    if (!!deleteImage) {
      setIsUploading({ ...isUploading, [deleteImage]: false })
      setDeleteImage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteImage])

  useEffect(() => {
    if (!!files) {
      dispatch(profileThunks.getScanData(files));
    }
    if (profile.idexpirydate === null) {
      setLocalState({ ...localState, profile: { ...localState.profile, "isexpiry": false } });
    }
    if (profile.idexpirydate === undefined || !!profile.idexpirydate) {
      setLocalState({ ...localState, profile: { ...localState.profile, "isexpiry": true } });
    }
    [
      "id-front.jpg",
      "id-back.jpg",
      "physical-address.jpg",
      "hold-id.jpg",
    ].forEach((picture) => {
      const img = new Image();
      if (picture === "hold-id.jpg") {
        img.src = utilityFns.getImageFromStore(picture, '10');
      } else {
        img.src = utilityFns.getImageFromStore(picture, '09');
      }
      window[picture] = img;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = (value, kind) => {
    return (event) => {

      if (event.cleanup) {
        const imageData = files[value];
        profileAPI.removeRegistrationScan(profile, imageData);
        imageData.image = undefined;
        files[value] = undefined;
        setLocalState({ ...localState, files });
      } else {
        const file = event.target.files[0];
        if (file.size > MAX_IMAGE_SIZE) {
          setToastInfo(
            "Notification",
            `File ${file.name} size ${file.size} exceeds acceptable size 5Mb`,
            true
          );
          return;
        }
        const ext = file.name.split(/\./).splice(-1)[0].toLowerCase();
        if (!ALLOWED_IMAGE_EXT.includes(ext)) {
          setToastInfo(
            "Notification",
            `File ${file.name
            } extension ${ext} is not from allowed extension list ${JSON.stringify(
              ALLOWED_IMAGE_EXT
            )}`,
            true
          );
          return;
        }
        event.target.value = "";
        const imageData = { file, kind };
        files[value] = imageData;
        var reader = new FileReader();
        reader.onload = (event) => {
          imageData.image = event.target.result;
          dispatch(updateFilesAC(files))
          setIsUploading({ ...isUploading, [kind]: true })
          setLocalState({ ...localState, scans: files });
          profileAPI
            .uploadRegistrationScan(profile, imageData, dispatch)
            .then((img) => {
              setDeleteImage(kind)
            });
        };
        reader.readAsDataURL(imageData.file);
      }
    };
  };

  const setIsExpiryValue = () => {
    if (!profile.isexpiry) {
      if (profile.idexpirydate === undefined || !!profile.idexpirydate) {
        return true
      }
      if (profile.idexpirydate === null) {
        return false
      }
    }
    return profile.isexpiry
  };
  const setExpityDateDisable = () => {
    if (profile.isexpiry) {
      return !profile.isexpiry
    } else {
      if (profile.idexpirydate === undefined) {
        return false
      }
      if (profile.idexpirydate === null) {
        return true
      } else {
        return !profile.idexpirydate
      }
    }
  };

  const removeAttachmentHandler = (fileItem) => {
    setToastInfo(
      "Confirming action",
      `Are you sure you want to withdraw this image ?`,
      true,
      "remove-image"
    );
    setToastInfoData(fileItem)
  }

  const checkIfDateIsValid =
    localState.profile.idexpirydate &&
    !(
      new Date().getTime() + 90 * 86400 * 1000 <
      new Date(localState.profile.idexpirydate).getTime()
    );

  return (
    <div className="id-verification-wrapper">
      <h4 id="idverification">ID Verification</h4>
      <div>
        <div>
          <p>
            We are required to collect and verify personal information of our
            applicants in order to comply with the government anti-money
            laundering regulations. We treat all your personal information with
            confidentiality and security in mind. Your privacy is of utmost
            importance to us, in accordance with our <a target="_blank" rel="noopener noreferrer" href="https://zimtra.ky/privacy-policy/">Privacy Policy</a>.
          </p>
        </div>
        <div>
          <p>
            Please note we support the following file formats "jpg", "jpeg", "png", "pdf".
            Maximum File size is 5MB.
          </p>
        </div>
        <hr />
        <div>
          <h5>Government Issued Photo ID</h5>
          <p>
            Please upload front and back of one piece of a valid Government
            Issued Photo ID. This document is used to verify your Full Name, Date
            of Birth, ID Number, Picture and Expiry Date.
          </p>
          <div className="id-type-number-expire">
            <Dropdown
              placeholder="Select type"
              label="ID type"
              options={IDtypes}
              name="idtype"
              value={profile.idtype || ''}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Required"
              label="ID number"
              name="idnumber"
              className="id-input-field"
              value={profile.idnumber || ''}
              onChange={handleChange}
              onValidate={localState.profile.idnumber && utilityFns.validIDNumber}
              messagefornotvalid="Use only Latin letters and numbers"
              maxLength="30"
              required
            />
          </div>
          <div className="expiry-date-section">
            <DateInput
              formnotvalid={checkIfDateIsValid ? checkIfDateIsValid
                : undefined}
              label="Expiry date"
              disabled={setExpityDateDisable()}
              name="idexpirydate"
              messagefornotvalid="Please select a valid id expiry date to proceed with registration (at
              least 90 days)."
              placeholder="ID expiry date"
              value={profile.idexpirydate || ''}
              max={new Date(new Date().getTime() + 50 * 365 * 86400 * 1000).toLocaleString('sv').split(" ")[0]}
              onChange={handleChange}
              required
            />
            <div className="checkbox-expiry-section">
              <Checkbox
                name="isexpiry"
                value={setIsExpiryValue()}
                onChange={handleChange}
              />
              <span>(ID has expiry date)</span>
            </div>
          </div>
          <div className="front-back-photo">
            <UploadFile
              label="PhotoID"
              placeholder2="One piece of valid government-issued photo ID: Front. Max: 5MB."
              example={window["id-front.jpg"]?.src}
              fileItem={files?.photoID}
              isUploading={isUploading}
              isDownloading={props.showSpinner}
              onChange={handleFileChange("photoID", "photoID")}
              name="photoID"
              image={files?.photoID?.image}
              accept="image/png, image/jpeg, image/jpg,.pdf"
              removeAttachmentHandler={removeAttachmentHandler}
              required
            />

            <UploadFile
              label="Photo ID back (if applicable)"
              placeholder2="One piece of valid government-issued photo ID: Back (when applicable). Max: 5MB."
              example={window["id-back.jpg"]?.src}
              fileItem={files?.photoIDback}
              name="photoIDback"
              isDownloading={props.showSpinner}
              isUploading={isUploading}
              onChange={handleFileChange("photoIDback", "photoIDback")}
              image={files?.photoIDback?.image}
              removeAttachmentHandler={removeAttachmentHandler}
              accept="image/png, image/jpeg, image/jpg,.pdf"
            />
          </div>
          <hr />
          <div>
            <h5>Self Photo</h5>
            <p>
              Please upload a photo of yourself holding the government-issued photo ID (from step 1) next to your face along with a hand-written note containing the following:
              <br />
              <ul>
                <li>Your First and Last Name</li>
                <li>Today’s date</li>
                <li>Your signature</li>
              </ul>
              This self-photo is for verification purposes. Please see the example below.
            </p>
            <UploadFile
              label="Proof of Identity"
              placeholder2="Upload a selfie holding your ID and a handwritten note with your name, date, and signature. Max: 5MB."
              fileItem={files?.identity}
              example={window["hold-id.jpg"]?.src}
              onChange={handleFileChange("identity", "identity")}
              name="identity"
              isDownloading={props.showSpinner}
              image={files?.identity?.image}
              isUploading={isUploading}
              accept="image/png, image/jpeg, image/jpg,.pdf"
              removeAttachmentHandler={removeAttachmentHandler}
              required
            />
          </div>
          <hr />
          <div>
            <h5>Proof of Physical Address</h5>
            <p>
              Please upload a proof of your physical address (as indicated in
              this application). The document can be a utility bill, phone bill,
              or bank statement. This document MUST have been issued in the last
              3 months. This can be a second piece of government ID, but it must
              have been issued in the last 3 months. This document is used to
              verify your full name and current address.
            </p>
            <UploadFile
              label="Proof of address"
              placeholder2="Proof of physical address. Max: 5MB."
              example={window["physical-address.jpg"]?.src}
              name="address"
              fileItem={files?.address}
              onChange={handleFileChange("address", "address")}
              isDownloading={props.showSpinner}
              image={files?.address?.image}
              isUploading={isUploading}
              accept="image/png, image/jpeg, image/jpg,.pdf"
              removeAttachmentHandler={removeAttachmentHandler}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(IDVerificationStep);
