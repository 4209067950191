import React, { useMemo, useState } from "react";

import {
  TO_SIGN_IN,
  TO_SIGN_UP,
  TO_SIGN_UP_CONFIRMATION,
  TO_ACTIVATION_PAGE,
  TO_RESET_PASSWORD,
  TO_ACTIVATE_CONFIRMATION,
} from "../reducers/types";
import ActivationPage from "./auth/activation/ActivationPage";
import Confirmation from "./auth/confirmation/Confirmation";
import SignInPage from "./auth/signin/SignIn";
import SignUpPage from "./auth/signup/SignUpPage";
import Button from "./utilities/element/Button";

import "./layout.scss";

const CN = "not-layout-block";

const NotAuthLayout = ({ destination, token }) => {
  const [tokenExpiredWindow, setTokenExpiredWindow] = useState(false);

  const MAX_INNER_WIDTH = 500;
  const renderLeftSide = () => {
    return (
      <div className="left-block">
        <h2>Open Your Trading Account</h2>
        <ul>
          <li>
            Create your profile
            <div className="step-circle" />
          </li>
          <li>
            Complete your application
            <div className="step-circle" />
          </li>
          <li>
            Select your account type
            <div className="step-circle" />
          </li>
          <li>
            Pay your refundable fee
            <div className="step-circle" />
          </li>
        </ul>
        <h2 className="lower-testimomial-title">Start Trading!</h2>
      </div>
    );
  };
  const renderSignInLeftSide = () => {
    return (
      <div className="left-block main-color">
        <ul>
          <li>
            $225M+ in Payouts to Zimtra Traders since 2017
            <div className="checkmark">
              <div className="checkmark_stem"></div>
              <div className="checkmark_kick"></div>
            </div>
          </li>
          <li>
            5,000+ Traders
            <div className="checkmark">
              <div className="checkmark_stem"></div>
              <div className="checkmark_kick"></div>
            </div>
          </li>
          <li>
            100M+ Shares Traded Daily
            <div className="checkmark">
              <div className="checkmark_stem"></div>
              <div className="checkmark_kick"></div>
            </div>
          </li>
          <li>
            $2Bn+ Traded Daily
            <div className="checkmark">
              <div className="checkmark_stem"></div>
              <div className="checkmark_kick"></div>
            </div>
          </li>
        </ul>
        <span>"MAKE YOUR DAY ..."</span>
      </div>
    )
  }
  const renderDestination = useMemo(() => {
    switch (destination) {
      case TO_SIGN_UP:
        return (
          <div className={`${CN}-main`}>
            {window.innerWidth > MAX_INNER_WIDTH && renderLeftSide()}
            {<SignUpPage />}
            {window.innerWidth <= MAX_INNER_WIDTH && renderLeftSide()}
          </div>
        );
      case TO_ACTIVATION_PAGE:
        return (
          <div className={`${CN}-main`}>
            {window.innerWidth > MAX_INNER_WIDTH && renderLeftSide()}
            {<ActivationPage token={token} setTokenExpiredWindow={setTokenExpiredWindow} />}
            {window.innerWidth <= MAX_INNER_WIDTH && renderLeftSide()}
          </div>
        );
      case TO_RESET_PASSWORD:
        return (
          <div className={`${CN}-main pd-top-10`}>
            {<ActivationPage token={token} resetPassword setTokenExpiredWindow={setTokenExpiredWindow} />}
          </div>
        );
      case TO_SIGN_IN:
        return (
          <div className={`${CN}-main`}>
            {window.innerWidth > MAX_INNER_WIDTH && renderSignInLeftSide()}
            {<SignInPage />}
            {window.innerWidth <= MAX_INNER_WIDTH && renderSignInLeftSide()}
          </div>
        );
      case TO_SIGN_UP_CONFIRMATION:
        return (
          <div className={`${CN}-main`}>
            <Confirmation to={TO_SIGN_UP_CONFIRMATION} />
          </div>
        );
      case TO_ACTIVATE_CONFIRMATION:
        return (
          <div className={`${CN}-main`}>
            <Confirmation to={TO_ACTIVATE_CONFIRMATION} />
          </div>
        );
      default:
        break;
    }
  }, [destination, token]);

  if (tokenExpiredWindow) {
    const handleStartAgain = (type) => {
      if (window.location.hostname.split(".")[0] === "zimtra") {
        return window.parent.location.replace(
          type === "activation"
            ? "https://zimtra.ky/account/sign-up"
            : "https://zimtra.ky/account/recover-password"
        )
      } else {
        return window.parent.location.replace(
          type === "activation"
            ? "https://stage.zimtra.ky/account/sign-up"
            : "https://stage.zimtra.ky/account/recover-password"
        )
      }
    }

    return (
      <div className="expired-token-dialog-block">
        <div className="expired-token-wrapper">
          <h4>This activation link has expired</h4>
          {tokenExpiredWindow.from === "activate"
            ? <span>Please start your registration again.</span>
            : <span>Please start your change password proccess again.</span>}
          <div className="button-section">
            {tokenExpiredWindow.from === "activate"
              ? <Button onClick={() => handleStartAgain("activation")}>
                Start registration
              </Button>
              : <Button onClick={() => handleStartAgain("pass")}>
                Change password again
              </Button>}
          </div>
        </div>
      </div>
    )
  }

  return renderDestination;
};

export default NotAuthLayout;
