import {
  FIRST_LAST_NAME,
  LOG_IN_TOKEN,
  REDIRECT_URL,
} from "../../reducers/types";

export const utilityFns = {
  validEnglishText: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^[A-Za-z \-.']*$/)) ||
      typeof t === "undefined"
    );
  },
  strToBoolean: (s) => {
    s = String(s).toLowerCase();
    return s === "true" || s === "yes" || s === "1";
  },
  validEnglishTextAndNumber: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^[A-Za-z0-9 \-.']*$/)) ||
      typeof t === "undefined"
    );
  },
  validEmail: (v) => {
    return (
      (typeof v === "string" &&
        !!v.match(
          /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
        )) ||
      typeof v === "undefined"
    );
  },
  validatePassword: (t) => {
    return (
      (typeof t === "string" &&
        !!t.match(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,40}$/
        ) &&
        t.slice(0, 1) !== " " &&
        t.slice(-1) !== " ") ||
      typeof t === "undefined"
    );
  },
  phoneNumber: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^(\+[0-9]+)?([0-9\\-]*)$/)) ||
      typeof t === "undefined" ||
      t.length === 0
    );
  },
  validIDNumber: (t) => {
    return typeof t === "string" && !!t.match(/^[A-Za-z0-9.\-#+:]+$/);
  },
  money: (v, frac = 0) => {
    const f = parseFloat(v);
    if (!isNaN(f)) {
      return f.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: frac,
      });
    }
    return v;
  },
  moneyshort: (v, frac = 1, noDollar = false) => {
    return (
      `${noDollar ? "" : "$"}` +
      new Intl.NumberFormat("en-US", {
        maximumFractionDigits: frac,
        notation: "compact",
        compactDisplay: "short",
      }).format(v)
    );
  },
  getMarketId: (m) => {
    return `${m.group}-${m.name}`;
  },
  toFixed: (x, pref, max) => {
    const xmax = x.toFixed(max);
    return xmax.slice(-1) === "0" ? x.toFixed(pref) : xmax;
  },
  emptyString: (v) => {
    return typeof v !== "undefined" && v.trim().length !== 0;
  },
  sortByOrderField: (a, b) => {
    return a.order < b.order ? -1 : a.order === b.order ? 0 : 1;
  },
  showForMeOrRostik: (profile) => {
    return (
      profile.email === "kazak13v@gmail.com" ||
      profile.email === "Stoxro@gmail.com" ||
      profile.email === "r.v.tataryn@gmail.com"
    );
  },
  checkAvailableAllRates: (rates) => {
    return Object.keys(rates).every((r) => rates[r].length > 0);
  },
  getImageFromStore: (imageName, place, year = "2022") => {
    const worigin = window.location.origin;
    if (worigin === "https://zimtra.ky") {
      return `${worigin}/wp-content/uploads/${year}/${place}/${imageName}`;
    }
    return `${process.env.PUBLIC_URL}/${imageName}`;
  },
  addRemoveScrollEventToBody: (add, _event) => {
    const isMobile = window.innerWidth <= 500;
    const slider = document.getElementById("slide-out-widget-area-bg");
    if (add) {
      document
        .querySelector("body")
        .addEventListener(_event, preventScroll, { passive: false });
      document.querySelector("body").classList.add("hidden-scrollbar");
      if (!isMobile) {
        document
          .querySelector("body")
          .classList.add("no-scrollbar-padding-right");
      }
      if (slider) slider.classList.add("no-scrollbar-set-bg");
    } else {
      document
        .querySelector("body")
        .removeEventListener(_event, preventScroll, { passive: false });
      document.querySelector("body").classList.remove("hidden-scrollbar");
      if (!isMobile) {
        document
          .querySelector("body")
          .classList.remove("no-scrollbar-padding-right");
      }
      if (slider) slider.classList.remove("no-scrollbar-set-bg");
    }
  },
  eraseTokens: () => {
    localStorage.removeItem(REDIRECT_URL)
    localStorage.removeItem(LOG_IN_TOKEN)
    sessionStorage.removeItem(REDIRECT_URL);
    
    localStorage.removeItem(FIRST_LAST_NAME);
  },
  findMatchingBundle: (arr, bundles) => {
    if (!bundles || bundles.length === 0) {
      return false;
    }
    // Helper function to compare two arrays
    const arraysEqualSet = (arr1 = [], arr2 = []) => {
      const sortFn = (a, b) => a.localeCompare(b);
      const sortedArr1 = [...arr1].sort(sortFn);
      const sortedArr2 = [...arr2].sort(sortFn);
      return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
    };
  
    // Iterate through each bundle
    for (const bundle of bundles) {
      if (arraysEqualSet(arr, bundle.pack)) {
        return bundle.bundleName;
      }
    }
    return false;
  },
  redirectToLanding: (path) => {
    const host = window.location.hostname;
    const urls = {
      localhost: `https://dev.zimtra.at-hub.com/${path}`,
      "dev.onboarding.zimtra.at-hub.com": `https://dev.zimtra.at-hub.com/${path}`,
      "cloud-stage.onboarding.zimtra.com": `https://cloud-stage.zimtra.com/${path}`, //cloud front stage
      "onboarding.zimtra.ky": `https://zimtra.ky/${path}`,
    };
  
    if (urls[host]) {
      window.location.replace(urls[host]);
    } else {
      window.location.replace("https://zimtra.ky")
    }
  },
  redirectToPortal: () => {
    const host = window.location.hostname;
    const urls = {
      localhost: "https://dev.portal.zimtra.at-hub.com",
      "dev.onboarding.zimtra.at-hub.com": "https://dev.portal.zimtra.at-hub.com",
      "cloud-stage.onboarding.zimtra.com": "https://cloud-stage.portal.zimtra.com",
      "onboarding.zimtra.ky": "https://portal.zimtra.ky",
    };
  
    if (urls[host]) {
      window.location.replace(urls[host]);
    } else {
      window.location.replace("https://portal.zimtra.ky")
    }
  },
};

const preventScroll = (e) => {
  e.preventDefault();
  e.stopPropagation();
  return false;
};
